<template>
  <div>
    <h2>Your Cart</h2>

    <el-table :data="orderItems">
      <el-table-column>
        <template #default="scope">
          <el-image
            v-if="scope.row.item.imageUrl"
            :src="scope.row.item.imageUrl"
            style="width: 150px; height: 150px"
          ></el-image>
        </template>
      </el-table-column>

      <el-table-column prop="item.name" label="Name" />

      <el-table-column label="Unit price">
        <template #default="scope">
          {{ `$ ${scope.row.item.price}` }}
        </template>
      </el-table-column>

      <el-table-column prop="quantity" label="Quantity" />

      <el-table-column label="Total price">
        <template #default="scope">
          {{ `$ ${scope.row.item.price * scope.row.quantity}` }}
        </template>
      </el-table-column>

      <el-table-column>
        <template #default="scope">
          <el-button @click="deleteFromCart(scope.row)">
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-button 
      type="primary"
      @click="placeOrder" 
      :loading="loading"
      size="large" 
      style="margin-top: 20px"
    >
      Place Order ($ {{ totalPrice }})
    </el-button>
  </div>
</template>

<script>
import OrderService from '../services/OrderService'
import utils from '@/helper/utils.js'

export default {
  data() {
    return {
      loading: false,
      orderItems: this.$store.state.cart.orderItems,
    }
  },
  computed: {
    totalPrice() {
      return this.$store.state.cart.orderItems.reduce((total, oi) => total + oi.quantity * oi.item.price, 0);
    },
  },
  methods: {
    async deleteFromCart(orderItem) {
      try {
        await this.$confirm(
          'Do you want to delete this order item from your cart?',
          'Delete Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'info',
            customStyle: 'width: 80%'
          }
        )
      } catch (e) {
        return
      }
      
      this.$store.dispatch('cart/deleteFromCart', orderItem)

      this.$message.success('Order item deleted successfully')

      this.orderItems = this.$store.state.cart.orderItems
    },
    async placeOrder() {
      if (this.orderItems.length <= 0) {
        this.$message.error("There's no item in your cart")
        return
      }

      try {
        await this.$confirm(
          'Do you want to submit your order?',
          'Order Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'info',
            customStyle: 'width: 80%'
          }
        )
      } catch (e) {
        return
      }
      
      this.loading = true;

      try {
        await OrderService.create({
          orderItems: this.orderItems
        })
      } catch (e) {
        utils.handleHttpError(e, this)
        return
      } finally {
        this.loading = false;
      }
      
      this.$store.dispatch('cart/emptyCart')
      this.$router.push({ name: 'Checkout' })

      try {
        await this.$alert('Order placed successfully!', 'Success')
      } catch (e) {
        return
      }
    },
  },
}
</script>

<style>
</style>