import http from '../http-common';

const BASE_API_URL = '/order'

class OrderService {
  getAll() {
    return http.get(`${BASE_API_URL}`);
  }

  getAllByDate(dateStr) {
    return http.get(`${BASE_API_URL}/date/${dateStr}`);
  }

  getAllMy(pageNumber, pageSize) {
    return http.get(`${BASE_API_URL}/getAllMy?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }

  get(id) {
    return http.get(`${BASE_API_URL}/${id}`);
  }

  create(data) {
    return http.post(`${BASE_API_URL}`, data);
  }

  update(id, data) {
    return http.put(`${BASE_API_URL}/${id}`, data);
  }

  updateQuantity(id, data) {
    return http.put(`${BASE_API_URL}/${id}/quantity`, data);
  }

  updatePayment(id, data) {
    return http.put(`${BASE_API_URL}/${id}/payment`, data);
  }

  updateArchived(id, data) {
    return http.put(`${BASE_API_URL}/${id}/archived`, data);
  }

  archiveMyOrder(id) {
    return http.get(`${BASE_API_URL}/${id}/archiveMyOrder`);
  }

  getMyOrdersByMenuDate(menuDate) {
    return http.get(`${BASE_API_URL}/getMyOrdersByMenuDate?menuDate=${menuDate}`)
  }
}

export default new OrderService();
